import { createConfirmation } from 'react-confirm';
import ConfirmDialog from "./index";

// create confirm function
export const confirmationComponent = createConfirmation(ConfirmDialog);

// This is optional. But wrapping function makes it easy to use.
export function confirm(confirmation?: any, options: any = {}) {
    return confirmationComponent({ confirmation, options });
}
